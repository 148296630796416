import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  alpha,
  colors,
} from "@mui/material";
import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Colors } from "../../../Constant/Color";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import BasicSelect from "../../../Components/Dropdown";
import { HexColorPicker } from "react-colorful";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CustomButton from "../../../Components/Button";
import axios from "axios";
import { Base_Uri, Image_Uri } from "../../../Constant/BaseUri";
import ProductAddModal from "../../../Components/fileProductaddModal";
import { RingLoader } from "react-spinners";
import CustomModal from "../../../Components/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  updateColors,
  updateProductDepartment,
  updateProductMake,
  updateProducts,
  updateSuppliers,
} from "../../../Redux/LoginReduces";
import { Excel_File_Base64 } from "../../../Constant/exceltempbase64";
import DepartmentModal from "../../../Components/departmentModal";
import MakeModal from "../../../Components/MakeModal";


export default function AddNewStock() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const location = useLocation()

  let routeData = location?.state



  const departments = useSelector(
    (state) => state.loginReducer.productDepartments
  );

  const make = useSelector(state => state?.loginReducer?.make)

  const product = useSelector(state => state?.loginReducer?.products)

  const suppliers = useSelector((state) => state.loginReducer.suppliers);


  const colors = useSelector(state => state.loginReducer?.colors)


  const fileInputRef = useRef(null);
  const [excelData, setExcelData] = useState(null);
  const [productAddModalVisible, setProductAddModalVisible] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [warrantyDuration, setWarrantyDuration] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subcategoryLoading, setSubcategoryLoading] = useState(false);
  const [departmentModalVisible, setDepartmentModalVisible] = useState(false);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [subCategoryModalVisible, setSubCategoryModalVisible] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [colorName, setColorName] = useState(false)
  const [colorLoading, setColorLoading] = useState(false)

  const [selectedSupplier, setSelectedSupplier] = useState("")

  const [editorState, setEditorState] = useState({
    bold: false,
    italic: false,
    underline: false,
    alignment: "left",
    marginTop: "10px",
  });
  const [complementoryProduct, setComplementoryProduct] = useState(false);
  const [deleteImage, setDeleteImage] = useState({
    image: false,
    image1: false,
    image2: false,
  });

  const initialDiscountCategory = [
    {
      id: 1,
      category: "A",
      discount: 0,
      name: "A Category",
    },
    {
      id: 2,
      category: "B",
      discount: 0,
      name: "B Category",
    },
  ];

  const [discountCategory, setDiscountCategory] = useState(
    initialDiscountCategory
  );

  const [selectedCategory, setSelectedCategory] = useState("select");
  const [selectedDepartment, setSelectedDepartment] = useState("select");
  const [categoryPer, setCategoryPer] = useState({
    a: "",
    b: "",
    c: "",
  });
  const [loading, setLoading] = useState(false)
  const [supplierDetails, setSupplierDetails] = useState({
    supplierName: "",
    shopDetails: "",
    mobileNumber: "",
  })
  const [productLoading, setProductLoading] = useState(false)
  const [allSuppliers, setAllSuppliers] = useState([])
  const [productDepartment, setProductDepartment] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("select");
  const [selectedMake, setSelectedMake] = useState("select")
  const [selectedModel, setSelectedModel] = useState("select")
  const [choosenColor, setChoosenColor] = useState("select")
  const [openMakeModalVisible, setOpenMakeModalVisible] = useState(false)
  const [openModelModalVisible, setOpenModelModalVisible] = useState(false)
  const [color, setColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isWarranty, setIsWarraty] = useState(null);
  const [image, setImage] = useState(null);
  const [barcodeData, setBarcodeData] = useState(""); // Initial barcode data
  const [skuData, setSkuData] = useState(""); // Initial barcode data
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [discardModal, setDiscardModal] = useState(false);

  const [openColorModal, setOpenColorModal] = useState(false)

  const [addNewSupplier, setAddNewSupplier] = useState(false)

  const [makeName, setMakeName] = useState("")
  const [modelName, setModelName] = useState("")

  useEffect(()=>{
    console.log(image,"image")
  },[image])

  const initialData = {
    ProductName: "",
    productDescription: "",
    // printProductName: "",
    // printProductDescription: "",
    // make: "",
    cost_price: "",
    trade_price: "",
    warehouse_price: "",
    compatibility: "",
    retail_price: "",
    // model: "",
    qty: "",
    remind: "",
    IMEI: "",
  };

  const [productData, setProductData] = useState(initialData);

  useEffect(() => {

    if (routeData && Object.keys(routeData).length > 0) {

      setSelectedCategory(routeData?.category)
      setSelectedDepartment(routeData?.department)
      setSelectedSubCategory(routeData?.sub_category)
      setSelectedMake(routeData?.make)
      setSelectedModel(routeData?.model)
      setChoosenColor(routeData?.product_color)
      setBarcodeData(routeData?.barcode)
      setSkuData(routeData?.sku)

      setImage(routeData?.image1_url)
      setImage1(routeData?.image2_url)
      setImage2(routeData?.image3_url)



      let supplierSelect = allSuppliers && allSuppliers?.length > 0 && allSuppliers?.filter((e, i) => e._id == routeData?.supplier_id)
      setSelectedSupplier(supplierSelect?.[0])


      setProductData({
        ProductName: routeData?.productName,
        productDescription: routeData?.product_description,
        cost_price: Number(routeData?.cost_price).toFixed(2),
        compatibility: routeData?.compatibility,
        IMEI: routeData?.IMEI,
        qty: Number(routeData?.qty),
        remind: Number(routeData?.reminder_qty),
      })

    }



  }, [routeData?.barcode, allSuppliers?.length])


  const getProducts = async () => {
    // setLoading(true)
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    }
  };

  const getDepartments = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProductDepartment`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            dispatch(updateProductDepartment(data.data));
            setProductDepartment(data?.data);
          }
        });
    }
  };

  const getMake = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProductMake`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            dispatch(updateProductMake(data.data));
            // setProductDepartment(data?.data);
          }
        });
    }
  };

  const getAllSuppliers = async () => {
    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getSuppliers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateSuppliers(data.data));
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const getColors = async () => {

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getColors`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateColors(data.data));
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });


  }




  useEffect(() => {
    getDepartments();
    getMake()
  }, [departments.length, make.length]);

  useEffect(() => {
    getAllSuppliers();
    getColors()
  }, []);



  useEffect(() => {


    if (productData?.cost_price && selectedCategory) {



      let selectedDep =
        productDepartment &&
        productDepartment.length > 0 &&
        productDepartment?.filter((e) => e?.departmentName == selectedDepartment);
      let selectedCat =
        selectedDep &&
        selectedDep[0]?.categories?.length > 0 &&
        selectedDep[0]?.categories?.filter(
          (e) => e?.categoryName == selectedCategory
        );
      selectedCat = selectedCat[0];


      setProductData({
        ...productData,
        trade_price: Math.round(Number(productData?.cost_price) + ((Number(productData?.cost_price) * Number(selectedCat?.categoryPer?.a)) / 100)),
        warehouse_price: Math.round(Number(productData?.cost_price) + ((Number(productData?.cost_price) * Number(selectedCat?.categoryPer?.b)) / 100)),
        retail_price: Math.round(Number(productData?.cost_price) + ((Number(productData?.cost_price) * Number(selectedCat?.categoryPer?.c)) / 100)),
      })

    }


  }, [productData?.cost_price, selectedCategory])


  React.useEffect(() => {

    setAllSuppliers(suppliers)

  }, [suppliers])




  const supplierName = suppliers && suppliers.length > 0 && suppliers.map((e, i) => {
    return e.supplierName
  })

  const downloadTemplate = () => {
    let base64 = Excel_File_Base64;
    let sliceSize = 1024;
    let byteCharacter = atob(base64);
    let bytesLenght = byteCharacter.length;
    let slicesCount = Math.ceil(bytesLenght / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLenght);
      let bytes = new Array(end - begin);

      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacter[offset].charCodeAt(0);
      }

      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });

    saveAs(new Blob([blob], {}), "my-excel.xlsx");
  };

  function pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  const generateNewBarcode = () => {



    if (product && product?.length > 0) {

      let length = product.length

      let lastProduct = product[length - 1]

      let lastBarcode = lastProduct?.barcode.toString()

      let newBarcodeData;
      if (lastBarcode !== null) {
        // Increment the last barcode serial wise
        const lastSerial = parseInt(lastBarcode.slice(-6)); // Extract last 6 digits as serial
        const newSerial = lastSerial + 1;
        newBarcodeData = lastBarcode.slice(0, -6) + pad(newSerial, 6); // Combine prefix and padded serial
      } else {
        // If no previous barcode, generate a random one as fallback
        newBarcodeData = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
      }

      setBarcodeData(newBarcodeData);

    } else {
      setBarcodeData(Math.floor(Math.random() * 1000000000).toString().padStart(9, '0'));



    }

  };

  const generateNewSku = ()=>{
    let ss = `SP${Math.floor(Math.floor(Math.random() * 600) * (Date.now() / 60000000))}`
    setSkuData(ss)
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files

      const file = acceptedFiles[0];

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please select a PNG or JPEG image.");
        return;
      }

      const formData = new FormData();
      formData.append("productImages", file);

      let data = await localStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .post(`${Base_Uri}uploadProductImage`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Include other headers as needed
          },
        })
        .then((res) => {
        
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            return;
          }

          if (data?.data?.filename) {
            if (!image) {
              setImage(data?.data?.filename);
            } else if (!image1) {
              setImage1(data?.data?.filename);
            } else {
              setImage2(data?.data?.filename);
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    [image, image1, image2]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteCategory = (ind) => {
    setDiscountCategory(discountCategory.filter((e, i) => i !== ind));
  };

  const closeColorPicker = () => {
    let length = selectedColor.length;

    setSelectedColor([
      ...selectedColor,
      {
        id: length + 1,
        color: color,
      },
    ]);

    setShowColorPicker(false);
  };

  const handleDeleteImage = (ind) => {
    if (ind == 0) {
      setImage("");
      setDeleteImage({
        ...deleteImage,
        image: false,
      });
    } else if (ind == 1) {
      setImage1("");
      setDeleteImage({
        ...deleteImage,
        image1: false,
      });
    } else if (ind == 2) {
      setImage2("");
      setDeleteImage({
        ...deleteImage,
        image2: false,
      });
    }
  };

  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      readExcel(file);
    }
  };

  const readExcel = (file) => {
    setFileLoading(true);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;

      if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
        alert("Invalid file. Please upload a valid Excel file.");
        setFileLoading(false);
        return;
      }

      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let arrayOfObjects = convertArrayToObject(jsonData);

      for (var i = 0; i < arrayOfObjects?.length; i++) {
        let data = arrayOfObjects[i];

        let {
          ProductName,
          productDescription,
          colors,
          image1,
          image2,
          image3,
          department,
          category,
          Sub_Category,
          make,
          model,
          qty,
          remind,
          supplier_name,
          supplier_address,
          supplier_mobile_number,
          cost_price,
          warehouse_price,
          trade_price,
          retail_price,
          barCode,
        } = data;

        if (
          !ProductName ||
          !department ||
          !category ||
          !Sub_Category ||
          !make ||
          !model ||
          !barCode
          // !cost_price ||
          // !trade_price ||
          // !retail_price ||
          // !warehouse_price
        ) {
          alert(
            `Required fields are missing check your file and make nacessary changes`
          );
          return;
        }

        // if (warranty && !warranty_duration) {
        //   alert(
        //     "You must provide warranty duration if there is product warranty"
        //   );
        //   return;
        // }
      }

      setExcelData(arrayOfObjects);
      setProductAddModalVisible(true);
    };

    reader.readAsBinaryString(file);
  };

  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const convertArrayToObject = (dataArray) => {
    if (Array.isArray(dataArray)) {
      const headers = dataArray[0];
      const dataObjects = [];

      for (let i = 1; i < dataArray.length; i++) {
        const currentRow = dataArray[i];
        const rowDataObject = {};

        if (currentRow.length > 0) {
          for (let j = 0; j < headers.length; j++) {
            rowDataObject[headers[j]] = currentRow[j];
          }
          dataObjects.push(rowDataObject);
        }
      }
      setFileLoading(false);
      return dataObjects;
    }
  };

  const handleSubmitFile = async () => {
    setSubmitLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .post(`${Base_Uri}addMultipleProducts`, excelData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data && data.status) {
          alert("File Submitted Successfully");
          setSubmitLoading(false);
          getProducts();
          setProductAddModalVisible(false);
          setExcelData([]);
        } else {
          alert(`${res.data.message}`);
          setSubmitLoading(false);
          setProductAddModalVisible(false);
        }
      }).catch((error) => {

        setSubmitLoading(false)

      });
  };

  const closeProductModal = () => {
    setProductAddModalVisible(false);
  };

  const handleDiscount = (event, ind) => {
    setDiscountCategory(
      discountCategory &&
      discountCategory?.length > 0 &&
      discountCategory?.map((e, i) => {
        if (i == ind) {
          return {
            ...e,
            discount: event,
          };
        } else {
          return e;
        }
      })
    );
  };

  const handleDiscardClick = () => {
    setDiscardModal(true);
  };

  const handleConfirmClick = () => {
    setProductData(initialData);
    setSelectedCategory("select");
    setSelectedDepartment("select");
    setSelectedColor([]);
    setSelectedSubCategory("select");
    setImage("");
    setImage1("");
    setImage2("");
    setBarcodeData("");
    setIsWarraty(false);
    setDiscountCategory(initialDiscountCategory);
    setDiscardModal(false);
  };

  const handleNextClick = async () => {






    if (selectedDepartment == "select" || !selectedDepartment) {
      alert("Selected department is missing");
      return;
    }

    if (selectedCategory == "select" || !selectedCategory) {
      alert("Selected Category is missing");
      return;
    }

    if (choosenColor == "select" || !choosenColor) {
      alert("Selected Color is missing");
      return;
    }


    if (selectedMake == "select" || !selectedMake) {
      alert("Make is missing")
      return
    }


    if (selectedMake == "select" || !selectedModel) {
      alert("Make is missing")
      return
    }

    if (!barcodeData) {
      alert("Barcode is missing");
      return;
    }

    if (!productData?.qty) {
      alert("Quantity is missing");
      return;
    }

    if (!productData?.cost_price) {
      alert("Cost Price is missing");
      return;
    }

    if (!productData?.trade_price && !routeData) {
      alert("Trade Price is missing");
      return;
    }

    if (!productData?.warehouse_price && !routeData) {
      alert("Warehouse Price is missing");
      return;
    }

    if (!productData?.retail_price && !routeData) {
      alert("Retail Price is missing");
      return;
    }


    if (!selectedSupplier || Object.keys(selectedSupplier).length == 0) {
      alert("Kindly select supplier")
      return
    }
    if (!skuData) {
      alert("Sku is missing")
      return
    }



    let selectedDep =
      productDepartment &&
      productDepartment.length > 0 &&
      productDepartment?.filter((e) => e?.departmentName == selectedDepartment);
    let selectedCat =
      selectedDep &&
      selectedDep[0]?.categories?.length > 0 &&
      selectedDep[0]?.categories?.filter(
        (e) => e?.categoryName == selectedCategory
      );
    selectedCat = selectedCat[0];


    let allData = { ...productData }

    if (!allData?.ProductName) {

      if (selectedSubCategory && selectedSubCategory !== "select") {
        allData.ProductName = selectedMake + " " + selectedModel + " " + selectedCategory + " " + selectedSubCategory + " " + choosenColor
      } else {
        allData.ProductName = selectedMake + " " + selectedModel + " " + selectedCategory + " " + choosenColor

      }
    }


    let data = {
      ...allData,
      department: selectedDepartment,
      Sub_Category: selectedSubCategory,
      category: selectedCategory,
      make: selectedMake,
      model: selectedModel,
      colors: choosenColor,
      categoryPer: selectedCat?.categoryPer,
      barCode: barcodeData,
      supplier_name: selectedSupplier.supplierName,
      supplier_address: selectedSupplier?.shopDetails,
      supplier_mobile_number: selectedSupplier?.mobileNumber,
      supplier_id: selectedSupplier?._id,
      image1: image,
      image2: image1,
      image3: image2,
      status: "Active",
      sku:skuData
    };


    setLoading(true)


    let myData = await localStorage.getItem("userData");
    myData = JSON.parse(myData);
    let token = myData


    if (routeData && routeData?.edit) {


      data.trade_price = data?.trade_price ? data?.trade_price : routeData?.trade_price
      data.warehouse_price = data?.warehouse_price ? data?.warehouse_price : routeData?.warehouse_price
      data.retail_price = data?.retail_price ? data?.retail_price : routeData?.retail_price


      axios.put(`${Base_Uri}editProducts`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      }).then((res) => {

        let data = res.data

        if (!data?.status) {
          alert(data.message)
          setLoading(false)
          return
        }

        if (data.status) {
          alert(data.message)
          setLoading(false)
          getProducts()
          setProductData(initialData)
          setSelectedCategory("select")
          setColor("")
          setBarcodeData("")
          setChoosenColor("select")
          setSelectedDepartment("select")
          setSelectedSubCategory("select")
          setSelectedModel("select")
          setSelectedMake("select")

          setImage("")
          setImage1("")
          setImage2("")
          setSelectedSupplier("select")
          setAllSuppliers(allSuppliers.map((e, i) => {
            return {
              ...e,
              selected: false
            }
          }))
          navigate("/Home")
        }


      }).catch((error) => {
        setLoading(false)
        console.log(error, "error")
      })




      return
    }



    axios.post(`${Base_Uri}addProduct`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      let data = res.data

      if (!data?.status) {
        alert(data.message)
        setLoading(false)
        return
      }

      if (data.status) {
        alert(data.message)
        setLoading(false)
        getProducts()
        setProductData(initialData)
        setSelectedCategory("select")
        setColor("")
        setBarcodeData("")
        setChoosenColor("select")
        setSelectedDepartment("select")
        setSelectedSubCategory("select")
        setSelectedModel("select")
        setSelectedSupplier("select")
        setSelectedMake("select")
        setImage("")
        setImage1("")
        setImage2("")
        setAllSuppliers(allSuppliers.map((e, i) => {
          return {
            ...e,
            selected: false
          }
        }))
        // navigate("/Home")
      }


    }).catch((error) => {
      setLoading(false)
      console.log(error, "error")
    })




    // navigate("./PriceDetails", { state: data });
  };

  const handleAddDepartment = async () => {
    if (!departmentName) {
      alert("Kindly add department name");
      return;
    }

    setDepartmentLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        departmentName: departmentName,
      };

      axios
        .post(`${Base_Uri}addProductDepartment`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setDepartmentLoading(false);
            return;
          }

          alert(data.message);
          getDepartments();
          setDepartmentModalVisible(false);
          setDepartmentLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          setDepartmentLoading(false);
          return;
        });
    }
  };
  const handleAddCategory = async () => {
    if (!selectedDepartment) {
      alert("Kindly add department");
      return;
    }

    if (!categoryName) {
      alert("Kindly add category name");
      return;
    }

    setCategoryLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        departmentName: selectedDepartment,
        categoryName: categoryName,
        categoryPer: categoryPer,
      };

      axios
        .post(`${Base_Uri}addProductCategory`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setCategoryLoading(false);
            return;
          }

          alert(data.message);
          setCategoryModalVisible(false);
          setCategoryLoading(false);
          getDepartments();
        })
        .catch((error) => {
          alert(error?.message);
          setCategoryLoading(false);
          return;
        });
    }
  };

  const handleAddSubCategory = async () => {
    if (!selectedDepartment) {
      alert("Kindly add department");
      return;
    }

    if (!selectedCategory) {
      alert("Kindly add category name");
      return;
    }

    if (!subcategoryName) {
      alert("Kindly add subcategory name");
      return;
    }

    setSubcategoryLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        departmentName: selectedDepartment,
        categoryName: selectedCategory,
        subcategoryName: subcategoryName,
      };

      axios
        .post(`${Base_Uri}addProductSubcategory`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setSubcategoryLoading(false);
            return;
          }

          alert(data.message);
          setSubCategoryModalVisible(false);
          setSubcategoryLoading(false);
          getDepartments();
        })
        .catch((error) => {
          alert(error?.message);
          setSubcategoryLoading(false);
          return;
        });
    }
  };

  const handleAddMake = async () => {


    if (!makeName) {
      alert("Kindly add make name");
      return;
    }

    setDepartmentLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        make: makeName,
      };

      axios
        .post(`${Base_Uri}addProductMake`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setDepartmentLoading(false);
            return;
          }

          alert(data.message);
          getMake();
          setOpenMakeModalVisible(false);
          setDepartmentLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          setDepartmentLoading(false);
          return;
        });
    }


  }

  const handleAddModel = async () => {


    if (!selectedMake) {
      alert("Kindly add Make");
      return;
    }

    if (!modelName) {
      alert("Kindly add model name");
      return;
    }

    setCategoryLoading(true);

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      let dataToSend = {
        make: selectedMake,
        model: modelName,

      };

      axios
        .post(`${Base_Uri}addProductModel`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setCategoryLoading(false);
            return;
          }

          alert(data.message);
          setOpenModelModalVisible(false);
          setCategoryLoading(false);
          getMake();
        })
        .catch((error) => {
          alert(error?.message);
          setCategoryLoading(false);
          return;
        });
    }


  }

  const selectSupplier = (supplierName) => {

    let supplierselect = allSuppliers?.filter((e, i) => e.supplierName == supplierName)

    supplierselect = supplierselect?.[0]

    setSelectedSupplier(supplierselect)

    setAllSuppliers(allSuppliers.map((e, i) => {

      if (e.supplierName == supplierName) {
        return {
          ...e,
          selected: true
        }
      } else {
        return {
          ...e,
          selected: false
        }
      }

    }))
  }

  let categories = [];
  let subcat = [];
  let model = [];

  if (selectedDepartment) {
    let getDepartment =
      productDepartment &&
      productDepartment.length > 0 &&
      productDepartment.filter((e, i) => {
        return e.departmentName == selectedDepartment;
      });

    if (getDepartment && getDepartment.length > 0) {
      let dept = getDepartment[0];

      categories =
        dept.categories &&
        dept.categories.length > 0 &&
        dept.categories.map((e, i) => {
          return e?.categoryName;
        });
    }
  }

  if (selectedMake) {
    let getModal =
      make &&
      make.length > 0 &&
      make.filter((e, i) => {
        return e.make == selectedMake;
      });


    if (getModal && getModal.length > 0) {
      let dept = getModal[0];



      model =
        dept.model &&
        dept.model.length > 0 &&
        dept.model.map((e, i) => {
          return e?.model;
        });
    }
  }

  if (selectedDepartment && selectedCategory) {
    let getDepartment =
      productDepartment &&
      productDepartment.length > 0 &&
      productDepartment.filter((e, i) => {
        return e.departmentName == selectedDepartment;
      });

    if (getDepartment && getDepartment.length > 0) {
      let dept = getDepartment[0];

      let mySelectedCat =
        dept.categories &&
        dept.categories.length > 0 &&
        dept.categories.filter((e, i) => {
          return e?.categoryName == selectedCategory;
        });

      let selectedCat = mySelectedCat[0];

      subcat =
        selectedCat?.subcategories && selectedCat?.subcategories.length > 0
          ? selectedCat?.subcategories.map((e, i) => {

            return e.subcategoryName;
          })
          : [];
    }
  }



  const handleAddSupplier = async () => {

    if (!supplierDetails.supplierName) {
      alert("supplier name is missing")
      return
    }
    if (!supplierDetails.mobileNumber) {
      alert("mobile Number is missing")
      return
    }
    if (!supplierDetails.shopDetails) {
      alert("Shop details is missing")
      return
    }


    setLoading(true)

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;


    axios.post(`${Base_Uri}addSupplier`, supplierDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include other headers as needed
      },
    }).then((res) => {

      let data = res.data



      setLoading(false)



      if (data.status) {

        alert(data?.message)
        setLoading(false)
        getAllSuppliers()
        setAddNewSupplier(false)

      }
    }).catch((error) => {

      setLoading(false)

    })

  }



  const handleAddColor = async () => {

    if (!colorName) {

      alert("Enter Color Name")
      return
    }

    let dataToSend = {
      color: colorName
    }

    setColorLoading(true)

    let data = await localStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .post(
        `${Base_Uri}addColor`, dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },

        }
      ).
      then((res) => {


        let data = res.data
        if (!data?.status) {
          alert(data.message)
          setColorLoading(false)
          return
        }



        let newColor = data.data

        let allColors = [...colors, newColor]
        setColorLoading(false)
        alert(data.message)
        setOpenColorModal(false)
        dispatch(updateColors(allColors))
      })
      .catch((error) => {
        console.log(error, "error")
        alert("Internal Server Error")
        setColorLoading(false)
        return
      })


  }


  return fileLoading || submitLoading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <RingLoader
        loading={fileLoading || submitLoading}
        size={100}
        color={Colors.black}
      />
      <Typography
        style={{ fontSize: "24px", color: Colors.black, fontFamily: "Poppins" }}
      >
        {fileLoading ? "Checking File..." : "Submiting File..."}
      </Typography>
    </Box>
  ) : (
    <Box style={{ color: "black" }}>
      <Box
        style={{
          width: "100%",
          marginTop: 30,
          padding: "0px 10px 0px 10px",
          backgroundColor: Colors?.white,
        }}
      >
        {productAddModalVisible && (
          <ProductAddModal
            heading="File Uploaded"
            text={"Are you sure you want to add this file product"}
            confirm={handleSubmitFile}
            open={productAddModalVisible}
            closeModal={closeProductModal}
          />
        )}

        {/* {arrayOfObjects && arrayOfObjects?.length > 0 && <CustomButton onClick={handleSubmitFile} text="Submit file" />} */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box style={{ padding: 10 }}>
              {/* Content for the first column */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Product Details
                </Typography>

                <Button
                  onClick={handleButtonClick}
                  style={{
                    borderRadius: 20,
                    backgroundColor: Colors.button,
                    color: Colors.white,
                    fontSize: 12,
                    padding: 10,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  Upload CSV File
                </Button>

                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleExcelFileChange}
                />
              </Box>
              <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    color: Colors.text,
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Product Name
                </Typography>
                <TextField
                  value={productData?.ProductName ? productData?.ProductName : (((selectedMake.trim()) && selectedMake !== "select") && (selectedModel.trim() && selectedModel !== "select") && (selectedCategory.trim() && selectedCategory !== "select") && (selectedMake.trim() && selectedMake !== "select") && (selectedModel.trim() && selectedModel !== "select")) ? selectedMake.trim() + " " + selectedModel.trim() + " " + selectedCategory.trim() + " " + (selectedSubCategory !== "select" ? selectedSubCategory.trim() : "") + " " + (choosenColor !== "select" ? choosenColor.trim() : "") : ""}
                  onChange={(e) =>
                    setProductData((prev) => ({
                      ...prev,
                      ProductName: e.target.value,
                    }))
                  }
                  size="small"
                  placeholder="Type Product Name"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Description
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      padding: "0px 0px 0px 0px",
                      borderBottom: 0,
                      marginTop: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        border: `1px solid ${Colors.border}`,
                        justifyContent: { xs: "space-between" },
                        borderBottom: "0px",
                        padding: 1,
                        flexWrap: "wrap",
                        marginTop: 0.5,
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                      }}
                    >
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            bold: !editorState?.bold,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.bold
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/bold.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            italic: !editorState?.italic,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.italic
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/italic.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            underline: !editorState?.underline,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.underline
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/underline.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "left",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "left"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/left.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "center",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "center"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/center.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "right",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "right"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/right.png")} />
                      </button>
                    </Box>
                    <Box>
                      <textarea
                        value={productData.productDescription}
                        onChange={(e) =>
                          setProductData((prev) => ({
                            ...prev,
                            productDescription: e.target.value,
                          }))
                        }
                        size="small"
                        multiline={true}
                        row={10}
                        placeholder="Type Description..."
                        style={{
                          width: "100%",
                          fontSize: 14,
                          padding: "3px 10px 3px 10px",
                          marginTop: 1,
                          fontFamily: "Poppins",
                          border: `1px solid ${Colors.border}`,
                          height: 100,
                          fontWeight: editorState?.bold ? "bold" : "normal",
                          fontStyle: editorState?.italic ? "italic" : "normal",
                          textDecoration: editorState?.underline
                            ? "underline"
                            : "none",
                          textAlign: editorState?.alignment,
                          borderBottomLeftRadius: "5px",
                          borderBottomRightRadius: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Button
                onClick={() => setComplementoryProduct(!complementoryProduct)}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                  border: `2px dotted ${Colors.button}`,
                  padding: 5,
                  marginTop: 10,
                  backgroundColor: Colors.buttonLight,
                }}
              >
                {complementoryProduct ? (
                  <DeleteIcon fontSize="large" color={Colors.button} />
                ) : (
                  <AddIcon fontSize="large" color={Colors.button} />
                )}
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: Colors.button,
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  {complementoryProduct ? "Remove" : "Add"} Complementory
                  Product
                </Typography>
              </Button>
              {complementoryProduct && (
                <Box
                  style={{
                    border: `2px dotted ${Colors.button}`,
                    padding: 15,
                    marginTop: 10,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Product Name
                    </Typography>

                    <Button
                      style={{
                        borderRadius: 20,
                        backgroundColor: Colors.button,
                        color: Colors.white,
                        fontSize: 12,
                        padding: 10,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      Choose Product
                    </Button>
                  </Box>
                  <BasicSelect
                    style={{ marginTop: 0.5 }}
                    value={selectedCategory}
                    status="Select Product Category"
                    id={productCategory}
                    width={"100%"}
                    onChange={(e) => setSelectedCategory(e)}
                  />

                  <Box
                    style={{
                      padding: "15px 0px 0px 0px",
                      marginTop: 5,
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Quantity
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        padding: "0px 0px 0px 0px",
                        borderBottom: 0,
                        marginTop: 0.5,
                      }}
                    >
                      <TextField
                        // value={data.cityName}
                        // onChange={(e) =>
                        //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                        // }

                        // variant="standard"
                        size="small"
                        placeholder="Add Product Quantity"
                        sx={{
                          width: "100%",
                          fontSize: "10px",
                          padding: 0,
                          marginTop: 0.5,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )} */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Department
                </Typography>

                <CustomButton
                  onClick={() => setDepartmentModalVisible(true)}
                  text="Add Department"
                  style={{
                    width: "fit-content",
                    padding: 1,
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                />
              </Box>
              <BasicSelect
                style={{ marginTop: 0.5 }}
                value={selectedDepartment}
                status="Select Deparment"
                id={
                  productDepartment && productDepartment.length > 0
                    ? productDepartment.map((e) => e?.departmentName)
                    : []
                }
                width={"100%"}
                onChange={(e) => setSelectedDepartment(e)}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Category
                </Typography>
              </Box>
              <Box
                style={{
                  border: `2px dotted ${Colors.button}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Product Category
                  </Typography>

                  <CustomButton
                    onClick={() => setCategoryModalVisible(true)}
                    text="Add Category"
                    style={{
                      width: "fit-content",
                      padding: 1,
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  />
                </Box>
                <BasicSelect
                  style={{ marginTop: 0.5 }}
                  value={selectedCategory}
                  status="Select Product Category"
                  id={categories}
                  width={"100%"}
                  onChange={(e) => setSelectedCategory(e)}
                />

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Sub Category
                    </Typography>

                    <CustomButton
                      onClick={() => setSubCategoryModalVisible(true)}
                      text="Add Subcategory"
                      style={{
                        width: "fit-content",
                        padding: 1,
                        borderRadius: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    />
                  </Box>

                  <BasicSelect
                    style={{ marginTop: 0.5 }}
                    value={selectedSubCategory}
                    status="Select Product Subcategory"
                    id={subcat}
                    width={"100%"}
                    onChange={(e) => setSelectedSubCategory(e)}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Colors
                </Typography>

                <CustomButton
                  onClick={() => setOpenColorModal(true)}
                  text="Add Color"
                  style={{
                    width: "fit-content",
                    padding: 1,
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                />


              </Box>


              <BasicSelect
                style={{ marginTop: 0.5 }}
                value={choosenColor}
                status="Select Color"
                id={
                  colors && colors.length > 0
                    ? colors.map((e) => e?.colorName)
                    : []
                }
                width={"100%"}
                onChange={(e) => setChoosenColor(e)}
              />
              {/* <TextField
                value={color}
                onChange={(e) => setColor(e?.target.value)}
                size="small"
                placeholder="Enter Product Color"
                sx={{
                  width: "100%",
                  fontSize: "10px",
                  padding: 0,
                  marginTop: 0.5,
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              /> */}
              {/* <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column", // Default direction for xs screen size
                  flexWrap: "wrap",
                  border: `1px solid ${Colors.border}`,
                  padding: 10,
                  backgroundColor: showColorPicker ? color : Colors.white,
                }}
              > */}
              {/* {!color && (
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      marginBottom: 10, // Add margin for spacing
                    }}
                  >
                    {!color && "Add Product Color"}
                  </Typography>
                )} */}
              {/* {!showColorPicker && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center", // Center the items on xs screen size
                    }}
                  >
                    {selectedColor &&
                      selectedColor?.length > 0 &&
                      selectedColor?.map((e, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              width: 25,
                              height: 25,
                              backgroundColor: e?.color,
                              margin: 1, // Add margin for spacing between color boxes
                            }}
                          ></Box>
                        );
                      })}
                  </Box>
                )} */}
              {/* <Box style={{ width: "100%" }}>
                  {showColorPicker && (
                    <HexColorPicker
                      style={{ marginBottom: 10 }}
                      color={color}
                      onChange={handleColorChange}
                    />
                  )}
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%", // Make the button take the full width
                    }}
                    onClick={() =>
                      showColorPicker
                        ? closeColorPicker()
                        : setShowColorPicker(true)
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: Colors.button,
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        textAlign: "center", // Center the text on xs screen size
                      }}
                    >
                      {!showColorPicker ? "+ Add Color" : "Select Color"}
                    </Typography>
                  </Button>
                </Box> */}
              {/* </Box> */};
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 0,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Bar Code
                </Typography>
              </Box>
              <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Product Bar Code
                  </Typography>
                </Box>
                <TextField
                  value={barcodeData}
                  onChange={(e) => !routeData && setBarcodeData(e?.target.value)}
                  size="small"
                  placeholder="Enter Product Bar Code"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => !routeData && generateNewBarcode()}
                    style={{
                      color: "#141414",
                      fontSize: 14,
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      width: "100%",
                      border: `1px solid ${Colors.border}`,
                      backgroundColor: Colors.main,
                      padding: 10,
                    }}
                  >
                    Auto Generate Bar Code
                  </Button>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
  Product Sku
                  </Typography>
              </Box>
              <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Product Sku
                  </Typography>
                </Box>
                <TextField
                  value={skuData}
                  onChange={(e) => !routeData && setSkuData(e?.target.value)}
                  size="small"
                  placeholder="Enter Product Bar Code"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => !routeData && generateNewSku()}
                    style={{
                      color: "#141414",
                      fontSize: 14,
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      width: "100%",
                      border: `1px solid ${Colors.border}`,
                      backgroundColor: Colors.main,
                      padding: 10,
                    }}
                  >
                    Auto Generate Sku
                  </Button>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  p: 0,
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 20
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,

                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 18,

                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Supplier
                  </Typography>

                  <CustomButton
                    onClick={() => setAddNewSupplier(!addNewSupplier)}
                    text={
                      addNewSupplier
                        ? "Close Supplier Form"
                        : "Add New Supplier"
                    }
                    style={{ p: 1, fontSize: "12px", fontWeight: "400" }}
                  />
                </Box>



                {addNewSupplier && <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >

                  <Grid xs={12} md={5.5} >
                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                      Supplier Name:
                    </Typography>
                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        type="text"
                        onChange={(e) => setSupplierDetails({ ...supplierDetails, supplierName: e.target.value })}
                        fullWidth
                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                        placeholder="Enter Supplier Name..."
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>


                  </Grid>
                  <Grid xs={12} md={5.5} >
                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                      Shop Detail:
                    </Typography>
                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        type="text"
                        onChange={(e) => setSupplierDetails({ ...supplierDetails, shopDetails: e.target.value })}
                        fullWidth
                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                        placeholder="Enter Shop Details..."
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>

                  </Grid>
                  <Grid xs={12} md={5.5} >
                    <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                      Mobile Number:
                    </Typography>
                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0.5 }} >

                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        type="number"
                        onChange={(e) => setSupplierDetails({ ...supplierDetails, mobileNumber: e.target.value })}
                        fullWidth
                        sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                        placeholder="Enter Mobile Number..."
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>
                  </Grid>


                  <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }} >

                    <CustomButton onClick={() => handleAddSupplier()} text={loading ? <RingLoader loading={loading} size={30} /> : "Save"} style={{ p: 1, fontSize: "12px", fontWeight: "400" }} />

                  </Box>

                </Grid>}


                <BasicSelect style={{ marginTop: 1 }} value={selectedSupplier?.supplierName ? selectedSupplier?.supplierName : "select"} status="Select Supplier" id={supplierName} width={"100%"} onChange={(e) => selectSupplier(e)} />





              </Box>
              {/* <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Print
                </Typography>
              </Box> */}
              {/* <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    color: Colors.text,
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Main Text
                </Typography>
                <TextField
                  value={productData.printProductName}
                  onChange={(e) =>
                    setProductData((prev) => ({
                      ...prev,
                      printProductName: e.target.value,
                    }))
                  }
                  size="small"
                  placeholder="Type Product Name"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Description
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      padding: "0px 0px 0px 0px",
                      borderBottom: 0,
                      marginTop: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        border: `1px solid ${Colors.border}`,
                        justifyContent: { xs: "space-between" },
                        borderBottom: "0px",
                        padding: 1,
                        flexWrap: "wrap",
                        marginTop: 0.5,
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                      }}
                    >
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            bold: !editorState?.bold,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.bold
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/bold.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            italic: !editorState?.italic,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.italic
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/italic.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            underline: !editorState?.underline,
                          })
                        }
                        style={{
                          backgroundColor: editorState?.underline
                            ? Colors.buttonLight
                            : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/underline.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "left",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "left"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                          borderRadius: 5,
                        }}
                      >
                        <img src={require("../../../Images/left.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "center",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "center"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/center.png")} />
                      </button>
                      <button
                        onClick={() =>
                          setEditorState({
                            ...editorState,
                            alignment: "right",
                          })
                        }
                        style={{
                          backgroundColor:
                            editorState?.alignment == "right"
                              ? Colors.buttonLight
                              : Colors.white,
                          border: "0px",
                        }}
                      >
                        <img src={require("../../../Images/right.png")} />
                      </button>
                    </Box>
                    <Box>
                      <textarea
                        value={productData.printProductDescription}
                        onChange={(e) =>
                          setProductData((prev) => ({
                            ...prev,
                            printProductDescription: e.target.value,
                          }))
                        }
                        size="small"
                        multiline={true}
                        row={10}
                        placeholder="Type Description..."
                        style={{
                          width: "100%",
                          fontSize: 14,
                          padding: "3px 10px 3px 10px",
                          marginTop: 1,
                          fontFamily: "Poppins",
                          border: `1px solid ${Colors.border}`,
                          height: 100,
                          fontWeight: editorState?.bold ? "bold" : "normal",
                          fontStyle: editorState?.italic ? "italic" : "normal",
                          textDecoration: editorState?.underline
                            ? "underline"
                            : "none",
                          textAlign: editorState?.alignment,
                          borderBottomLeftRadius: "5px",
                          borderBottomRightRadius: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box style={{ padding: 10 }}>
              {/* Content for the second column */}

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                  paddingTop: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Product Images
                </Typography>

                <Button
                  onClick={downloadTemplate}
                  style={{
                    borderRadius: 20,
                    backgroundColor: Colors.button,
                    color: Colors.white,
                    fontSize: 12,
                    padding: 10,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  Download Excel Template
                </Button>
              </Box>
              <Box
                sx={{
                  padding: "10px",
                  border: `1px solid ${Colors.border}`,
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                  },

                  justifyContent: { lg: "space-around", xs: "space-between" },
                  width: "100%",
                }}
              >
                <Grid md={12} lg={4} sm={12}>
                  <Box
                    {...getRootProps()}
                    style={{
                      padding: 10,
                      height: 110,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: 5,
                      paddingRight: 20,
                      paddingLeft: 20,
                    }}
                  >
                    <Box
                      sx={{
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        backgroundColor: Colors.buttonLight,
                        borderRadius: "5px",
                        flexDirection: "column",
                        padding: "10px",
                      }}
                    >
                      <input
                        style={{
                          border: "2px solid red",
                          width: "100%",
                          height: "100%",
                          // padding: 100,
                        }}
                        {...getInputProps()}
                      />

                      <img
                        src={require("../../../Images/picker.png")}
                        style={{ height: 50 }}
                      />

                      {isDragActive ? (
                        <Typography>Drop the files here ...</Typography>
                      ) : (
                        <Box>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: 8,
                                sm: 10,
                                md: "10px",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                              },
                              color: Colors.button,
                              textDecoration: "underline",
                            }}
                          >
                            Click to upload{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "8px",
                                sm: "8px",
                                md: "10px",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                              },
                              color: Colors.text,
                              textDecoration: "none",
                            }}
                          >
                            or drag and drop
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    {/* <input
                                        type="file"
                                        id="avatar"
                                        accept="image/*"
                                        style={{
                                            display: "none",
                                            border: "2px solid red",
                                            padding: 10,
                                        }} */}
                    {/* // onChange={handleImageChange} */}
                    {/* /> */}
                  </Box>
                </Grid>

                <Grid lg={4} md={12} sm={12}  >
                  <Box
                    onDoubleClick={() =>
                      setDeleteImage({ ...deleteImage, image: true })
                    }
                    style={{
                      padding: 10,
                      height: 100,
                      width: 150,

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: Colors.white,
                      borderRadius: 5,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          deleteImage?.image && image
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        height: "100px",
                        width: "100%",
                        justifyContent: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {!image && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Image 1
                        </Typography>
                      )}
                      {image && !deleteImage?.image && (
                        <img
                          src={`${Image_Uri}products/${image}`}
                          style={{
                            maxHeight: "80%",
                            maxWidth: "80%",
                            backgroundColor:
                              deleteImage?.image && image
                                ? Colors.buttonLight
                                : Colors.white,

                          }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image && deleteImage?.image && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(0)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px",
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px",
                              mt: 1,
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}

                      {/* {image && <Box sx={{display:"flex",height:"100%",marginTop:"5x"}} > <CloseIcon fontSize="medium" color={Colors.button} /> </Box>} */}
                    </Box>
                  </Box>
                </Grid>
                <Grid lg={4} md={12} sm={12}  >
                  <Box
                    onDoubleClick={() =>
                      setDeleteImage({ ...deleteImage, image1: true })
                    }
                    style={{
                      padding: 10,
                      height: 100,
                      width: 150,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: Colors.white,
                      borderRadius: 5,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          deleteImage?.image1 && image1
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        height: "100px",
                        justifyContent: "center",
                        borderRadius: "5px",
                        zindex: 10,
                      }}
                    >
                      {!image1 && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Image 2
                        </Typography>
                      )}
                      {image1 && !deleteImage?.image1 && (
                        <img
                          src={`${Image_Uri}products/${image1}`}
                          style={{
                            maxHeight: "80%",
                            maxWidth: "80%",
                            backgroundColor:
                              deleteImage?.image1 && image1
                                ? Colors.buttonLight
                                : Colors.white,
                          }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image1 && deleteImage?.image1 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(1)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px"
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image1: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px",
                              mt: 1,
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}

                      {/* {image && <Box sx={{display:"flex",height:"100%",marginTop:"5x"}} > <CloseIcon fontSize="medium" color={Colors.button} /> </Box>} */}
                    </Box>
                  </Box>
                </Grid>

                <Grid lg={4} md={12} sm={12} >
                  <Box
                    onDoubleClick={() =>
                      setDeleteImage({ ...deleteImage, image2: true })
                    }
                    style={{
                      padding: 10,
                      // paddingRight:0,
                      // paddingLeft:0,
                      height: 100,
                      width: 150,
                      // margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: Colors.white,
                      borderRadius: 5,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          deleteImage?.image2 && image2
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        height: 100,
                        justifyContent: "center",
                        borderRadius: "5px",
                        zindex: 10,
                      }}
                    >
                      {!image2 && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Image 3
                        </Typography>
                      )}
                      {image2 && !deleteImage?.image2 && (
                        <img
                          src={`${Image_Uri}products/${image2}`}
                          style={{
                            maxHeight: "80%",
                            maxWidth: "80%",
                            backgroundColor:
                              deleteImage?.image2 && image2
                                ? Colors.buttonLight
                                : Colors.white,
                          }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image2 && deleteImage?.image2 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(2)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px"
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image2: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "50px",
                              fontSize: "10px",
                              mt: 1,
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}

                      {/* {image && <Box sx={{display:"flex",height:"100%",marginTop:"5x"}} > <CloseIcon fontSize="medium" color={Colors.button} /> </Box>} */}
                    </Box>
                  </Box>
                </Grid>


                {/* <Grid lg={3.8} md={12} sm={12}>
                  <Box
                    onDoubleClick={() =>
                      setDeleteImage({ ...deleteImage, image2: true })
                    }
                    style={{
                      padding: 10,
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: Colors.white,
                      borderRadius: 5,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          deleteImage?.image && image
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        height: "100px",
                        justifyContent: "center",
                        borderRadius: "5px",
                        zindex: 10,
                      }}
                    >
                      {!image2 && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Image 3
                        </Typography>
                      )}
                      {image2 && !deleteImage?.image2 && (
                        <img
                          src={`${Image_Uri}products/${image2}`}
                          style={{
                            height: "80%",
                            width: "80%",
                            backgroundColor:
                              deleteImage?.image2 && image2
                                ? Colors.buttonLight
                                : Colors.white,
                          }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image2 && deleteImage?.image2 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(2)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "50px",
                              fontSize:"10px",
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image2: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "50px",
                              fontSize:"10px",
                              mt: 1,
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}

                      {/* {image && <Box sx={{display:"flex",height:"100%",marginTop:"5x"}} > <CloseIcon fontSize="medium" color={Colors.button} /> </Box>} */}
                {/* </Box>
                  </Box>
                </Grid>  */}

                {/* <Grid md={12} lg={4} sm={12}>
                  <Box
                    style={{
                      padding: 10,
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: Colors.white,
                      borderRadius: 5,
                      // paddingRight: 20,
                      // paddingLeft: 20,
                    }}
                  >
                    <Box
                      onDoubleClick={() =>
                        setDeleteImage({ ...deleteImage, image1: true })
                      }
                      sx={{
                        backgroundColor:
                          deleteImage?.image1 && image1
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        height: 100,
                        justifyContent: "center",
                        borderRadius: "5px",
                      }}
                    >
                      {!image1 && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              height:"100px"
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Image 2
                        </Typography>
                      )}
                      {image1 && !deleteImage?.image1 && (
                        <img
                          src={`${Image_Uri}products/${image1}`}
                          style={{ height: "90%" }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image1 && deleteImage?.image1 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(1)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "100px",
                              fontSize: "12px",
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image1: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "120px",
                              mt: 0.5,
                              width: "100px",
                              fontSize: "12px",
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}
                    </Box>
                    <Box
                      onDoubleClick={() =>
                        setDeleteImage({ ...deleteImage, image2: true })
                      }
                      sx={{
                        backgroundColor:
                          deleteImage?.image2 && image2
                            ? Colors.buttonLight
                            : Colors.white,
                        border: `2px dotted ${Colors.button}`,
                        display: "flex",
                        alignItems: "center",
                        padding: 2,
                        justifyContent: "center",
                        height: "85px",
                        borderRadius: "5px",
                      }}
                    >
                      {!image2 && (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "16px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                            },
                            color: Colors.text,
                            textDecoration: "none",
                          }}
                        >
                          Product Image 03
                        </Typography>
                      )}
                      {image2 && !deleteImage?.image2 && (
                        <img
                          src={`${Image_Uri}products/${image2}`}
                          style={{ height: "90%" }}
                          alt="Uploaded"
                          className="preview-image"
                        />
                      )}

                      {image2 && deleteImage?.image2 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            onClick={() => handleDeleteImage(2)}
                            sx={{
                              backgroundColor: Colors.red,
                              color: Colors.white,
                              width: "100px",
                              fontSize: "12px",
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() =>
                              setDeleteImage({ ...deleteImage, image2: false })
                            }
                            sx={{
                              backgroundColor: Colors.button,
                              color: Colors.white,
                              width: "120px",
                              mt: 0.5,
                              width: "100px",
                              fontSize: "12px",
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid> */}
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Inventory Details
                </Typography>
              </Box>

              <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 15,
                    width: "100%"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Make
                  </Typography>

                  <CustomButton
                    onClick={() => setOpenMakeModalVisible(true)}
                    text="Add Make"
                    style={{
                      width: "fit-content",
                      padding: 1,
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  />
                </Box>
                <BasicSelect
                  style={{ marginTop: 0.5 }}
                  value={selectedMake}
                  status="Select Make"
                  id={
                    make && make.length > 0
                      ? make.map((e) => e?.make)
                      : []
                  }
                  width={"100%"}
                  onChange={(e) => setSelectedMake(e)}
                />


                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Model
                  </Typography>

                  <CustomButton
                    onClick={() => setOpenModelModalVisible(true)}
                    text="Add Model"
                    style={{
                      width: "fit-content",
                      padding: 1,
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  />

                </Box>

                <BasicSelect
                  style={{ marginTop: 0.5 }}
                  value={selectedModel}
                  status="Select Model"
                  id={model && model.length > 0 ? model : []}
                  width={"100%"}
                  onChange={(e) => setSelectedModel(e)}
                />

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Compatibility
                  </Typography>
                </Box>

                <TextField
                  value={productData.compatibility}
                  onChange={(e) =>
                    setProductData((prev) => ({
                      ...prev,
                      compatibility: e.target.value,
                    }))
                  }
                  size="small"
                  placeholder="Enter Compatibility"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    IMEI
                  </Typography>
                </Box>

                <TextField
                  value={productData.IMEI}
                  onChange={(e) =>
                    setProductData((prev) => ({
                      ...prev,
                      IMEI: e.target.value,
                    }))
                  }
                  size="small"
                  placeholder="Enter IMEI"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Quantity
                  </Typography>
                </Box>
                <TextField
                  value={productData.qty}
                  onChange={(e) =>
                    setProductData((prev) => ({ ...prev, qty: e.target.value }))
                  }
                  size="small"
                  placeholder="Add Product Quantity"
                  type="number"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    style={{
                      color: Colors.text,
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                    }}
                  >
                    Remind
                  </Typography>
                </Box>
                <TextField
                  value={productData.remind}
                  onChange={(e) =>
                    setProductData((prev) => ({
                      ...prev,
                      remind: e.target.value,
                    }))
                  }
                  size="small"
                  type="number"
                  placeholder="Remind me when left"
                  sx={{
                    width: "100%",
                    fontSize: "10px",
                    padding: 0,
                    marginTop: 0.5,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                />

              </Box>

              {/* <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Max Discount
                </Typography>
              </Box> */}

              {/* <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {discountCategory &&
                  discountCategory?.length > 0 &&
                  discountCategory?.map((e, i) => {
                    return (
                      <Box sx={{ width: "100%", marginTop: 2 }}>
                        <Grid
                          container
                          sm={12}
                          md={12}
                          xl={12}
                          xs={12}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={12} sm={3} md={4} lg={3} xl={2}>
                            <Typography
                              sx={{
                                color: Colors.text,
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "14px",
                                },
                                fontWeight: "bold",
                                fontFamily: "Poppins",
                                textAlign: "left",
                              }}
                            >
                              {e?.name}
                            </Typography>
                          </Grid>

                          <Grid item xs={9} sm={7} md={6} lg={7} xl={9}>
                            <TextField
                              value={e?.discount}
                              onChange={(event) =>
                                handleDiscount(event.target.value, i)
                              }
                              type="number"
                              variant="standard"
                              placeholder={`Enter Discount For ${e?.category} Category`}
                              size="small"
                              sx={{
                                width: "100%",
                                fontSize: "10px",
                                padding: 0,
                                marginTop: 0.5,
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} sm={2} md={2} lg={2} xl={1}>
                            <DeleteIcon
                              onClick={() => deleteCategory(i)}
                              fontSize={"large"}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}

                <Box
                  style={{
                    padding: "15px 0px 0px 0px",
                    marginTop: 5,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={addCategory}
                    style={{
                      color: "#141414",
                      fontSize: 14,
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      width: "100%",
                      border: `1px solid ${Colors.border}`,
                      backgroundColor: Colors.main,
                      padding: 10,
                    }}
                  >
                    Another Discount Category
                  </Button>
                </Box>
              </Box> */}

              {/* <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 15 }} >

                                <Typography style={{ fontSize: 20, fontWeight: "bold", fontFamily: "Poppins" }} >
                                    Credit Limitation
                                </Typography>

                                <input type="number" style={{ width: 120, textAlign: "right", border: `1px solid ${Colors.border}`, borderRadius: 5, color: creditLimit ? Colors.black : Colors.border, padding: 12, fontSize: 14 }} placeholder='$0.00' onChange={(e) => setCreditLimit(e.target.value)} />

                            </Box> */}

              {/* <Button onClick={() => setListOnOtherPlatform(true)} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5, border: `2px dotted ${Colors.button}`, padding: 10, marginTop: 15, backgroundColor: Colors.buttonLight }} >

                                <Typography sx={{ fontSize: { sm: "14px", md: "18px" }, color: Colors.button, fontFamily: "Poppins", fontWeight: "600" }} >
                                    List On Other Platform
                                </Typography>

                            </Button> */}

              {/* {listOnOtherPlatform && <Box style={{ border: `1px solid ${Colors.border}`, padding: 15, marginTop: 10, borderRadius: 5, display: "flex", alignItems: "flex-start", flexDirection: "column" }} > */}

              {/* {warehouses && warehouses?.length > 0 && warehouses?.map((e, i) => {
                                    return (

                                        <Box sx={{ width: "100%", marginTop: 2 }} >
                                            <Grid container sm={12} md={12} xl={12} xs={12} sx={{ display: "flex", alignItems: "center" }} >
                                                <Grid item xs={12} sm={3} md={4} lg={3} xl={2} >
                                                    <Typography sx={{ color: Colors.text, fontSize: { xs: "10px", sm: "12px", md: "14px" }, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                        {e?.name}
                                                    </Typography>
                                                </Grid>


                                                <Grid item xs={9} sm={7} md={6} lg={7} xl={9} >
                                                    <TextField
                                                        // value={data.cityName}
                                                        // onChange={(e) =>
                                                        //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                                        // }

                                                        // variant="standard"
                                                        size="small"
                                                        sx={{ width: "100%", fontSize: "10px", padding: 0, marginTop: 0.5, fontFamily: "Poppins", fontWeight: "bold" }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} sm={2} md={2} lg={2} xl={1} >
                                                    <DeleteIcon onClick={() => deleteWarehouse(i)} fontSize={"large"} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })} */}

              {/* </Box>} */}

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "20px",
                      lg: "22px",
                      xl: "24px",
                    },
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  Price
                </Typography>
              </Box>

              <Box
                style={{
                  border: `1px solid ${Colors.border}`,
                  padding: 15,
                  marginTop: 10,
                  borderRadius: 5,
                }}
              >
                <Grid
                  container
                  xs={12}
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid xs={12} md={5.5}>
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "left",
                      }}
                    >
                      Cost (X VAT)
                    </Typography>
                    <Box
                      sx={{
                        border: `1px solid ${Colors.border}`,
                        padding: "5px 5px 5px 5px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          backgroundColor: Colors.border,
                          fontSize: "16px",
                          color: "#141414",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        £
                      </Box>
                      <TextField
                        value={productData?.cost_price}
                        onChange={(e) => {
                          setProductData({
                            ...productData,
                            cost_price: e?.target?.value,
                          });
                        }}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{
                          width: "100%",
                          fontSize: "10px",
                          padding: 0,
                          margin: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        placeholder="00.00"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid xs={12} md={5.5}>
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "left",
                      }}
                    >
                      Trade (X VAT)
                    </Typography>
                    <Box
                      sx={{
                        border: `1px solid ${Colors.border}`,
                        padding: "5px 5px 5px 5px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          backgroundColor: Colors.border,
                          fontSize: "16px",
                          color: "#141414",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        £
                      </Box>
                      <TextField
                        value={productData?.trade_price ? productData?.trade_price : routeData?.trade_price ? routeData?.trade_price : ""}
                        onChange={(e) => {
                          setProductData({
                            ...productData,
                            trade_price: e?.target?.value,
                          });
                        }}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{
                          width: "100%",
                          fontSize: "10px",
                          padding: 0,
                          margin: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        placeholder="00.00"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={12}
                  md={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <Grid xs={12} md={5.5}>
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "left",
                      }}
                    >
                      Warehouse (X VAT)
                    </Typography>
                    <Box
                      sx={{
                        border: `1px solid ${Colors.border}`,
                        padding: "5px 5px 5px 5px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          backgroundColor: Colors.border,
                          fontSize: "16px",
                          color: "#141414",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        £
                      </Box>
                      <TextField
                        value={productData?.warehouse_price ? productData?.warehouse_price : routeData?.warehouse_price ? routeData?.warehouse_price : ""}
                        onChange={(e) => {
                          setProductData({
                            ...productData,
                            warehouse_price: e?.target?.value,
                          });
                        }}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{
                          width: "100%",
                          fontSize: "10px",
                          padding: 0,
                          margin: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        placeholder="00.00"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} md={5.5}>
                    <Typography
                      style={{
                        color: Colors.text,
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "left",
                      }}
                    >
                      Retail (W VAT)
                    </Typography>
                    <Box
                      sx={{
                        border: `1px solid ${Colors.border}`,
                        padding: "5px 5px 5px 5px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          backgroundColor: Colors.border,
                          fontSize: "16px",
                          color: "#141414",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        £
                      </Box>
                      <TextField
                        value={productData?.retail_price ? productData?.retail_price : routeData?.retail_price ? routeData?.retail_price : ""}
                        onChange={(e) => {
                          setProductData({
                            ...productData,
                            retail_price: e?.target?.value,
                          });
                        }}
                        variant="standard" // <== changed this
                        margin="normal"
                        required
                        fullWidth
                        sx={{
                          width: "100%",
                          fontSize: "10px",
                          padding: 0,
                          margin: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        placeholder="00.00"
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true, // <== added this
                          // fontSize: "2px"
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: { xs: 2, sm: 2, md: 0 },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "45%",
                      lg: "55%",
                      md: "65%",
                      sm: "55%",
                      xs: "80%",
                    },
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={handleDiscardClick}
                    sx={{
                      backgroundColor: Colors.white,
                      color: Colors.button,
                      border: `1px solid ${Colors.button}`,
                      borderRadius: 5,
                      padding: "10px 25px 10px 25px",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      width: "48%",
                    }}
                  >
                    Discard
                  </Button>

                  <Button
                    onClick={() => handleNextClick()}
                    sx={{
                      backgroundColor: Colors.button,
                      color: Colors.white,
                      border: `1px solid ${Colors.button}`,
                      borderRadius: 5,
                      padding: "10px 25px 10px 25px",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      width: "48%",
                      '&:hover': {
                        color: Colors.button, // Set your desired hover color
                      },
                    }}
                  >
                    {loading ? <RingLoader loading={loading} size={30} color={Colors.black} /> : routeData ? "Save" : "Save"}
                  </Button>
                </Box>
              </Box>

              {departmentModalVisible && (
                <DepartmentModal
                  open={departmentModalVisible}
                  placeholder={"Enter Department Name..."}
                  loading={departmentLoading}
                  onChange={(e) => setDepartmentName(e.target.value)}
                  closeModal={() => setDepartmentModalVisible(false)}
                  heading={"Add Department"}
                  confirm={() => handleAddDepartment()}
                />
              )}

              {openMakeModalVisible && (
                <MakeModal
                  open={openMakeModalVisible}
                  placeholder={"Enter Make..."}
                  loading={departmentLoading}
                  onChange={(e) => setMakeName(e.target.value)}
                  closeModal={() => setOpenMakeModalVisible(false)}
                  heading={"Add Make"}
                  confirm={() => handleAddMake()}
                />
              )}

              {openModelModalVisible && (
                <MakeModal
                  open={openModelModalVisible}
                  selectedMake={selectedMake}
                  setSelectedMake={setSelectedMake}
                  placeholder={"Enter Model Name..."}
                  loading={categoryLoading}
                  showMake={true}
                  onChange={(e) => setModelName(e.target.value)}
                  closeModal={() => setOpenModelModalVisible(false)}
                  heading={"Add Model"}
                  confirm={() => handleAddModel()}
                />
              )}

              {categoryModalVisible && (
                <DepartmentModal
                  open={categoryModalVisible}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                  placeholder={"Enter Category Name..."}
                  loading={categoryLoading}
                  showDeparment={true}
                  onChange1={(e) =>
                    setCategoryPer({ ...categoryPer, a: e.target.value })
                  }
                  onChange2={(e) =>
                    setCategoryPer({ ...categoryPer, b: e.target.value })
                  }
                  onChange3={(e) =>
                    setCategoryPer({ ...categoryPer, c: e.target.value })
                  }
                  onChange={(e) => setCategoryName(e.target.value)}
                  closeModal={() => setCategoryModalVisible(false)}
                  heading={"Add Category"}
                  confirm={() => handleAddCategory()}
                />
              )}

              {subCategoryModalVisible && (
                <DepartmentModal
                  open={subCategoryModalVisible}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  placeholder={"Enter Subcategory Name..."}
                  loading={subcategoryLoading}
                  showDeparment={true}
                  showCategory={true}
                  onChange={(e) => setSubcategoryName(e.target.value)}
                  closeModal={() => setSubCategoryModalVisible(false)}
                  heading={"Add Category"}
                  confirm={() => handleAddSubCategory()}
                />
              )}

              {discardModal && (
                <CustomModal
                  open={discardModal}
                  closeModal={() => setDiscardModal(false)}
                  heading={"Discard Changes"}
                  confirm={() => handleConfirmClick()}
                  text={"Are your sure you want to discard this changes."}
                />
              )}


              {openColorModal && (
                <CustomModal
                  open={openColorModal}
                  closeModal={() => setOpenColorModal(false)}
                  heading={"Add Color"}
                  credit={true}
                  placeholder={"Enter Color Name"}
                  loading={colorLoading}
                  onChange={(e) => setColorName(e?.target?.value)}
                  confirm={() => handleAddColor()}
                  text={"Are your sure you want to add this changes."}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
